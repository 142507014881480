import './App.css';
import Nav from './components/Nav';
import ProfileCreated from './components/ProfileCreated';
import ProfileEdit from './components/ProfileEdit';
import StudioPage from './components/StudioPage';
import HomePage from './components/HomePage';
import ProfileCreatedMedia from './components/ProfileCreatedMedia';
import StudioDetail from './components/StudioDetail';
import DownloadSection from './components/DownloadSection';
import MediaVideo from './components/MediaVideo'
import MediaAudio from './components/MediaAudio';
import Profile from "./components/Profile"


import {
  BrowserRouter ,
  Routes,
  Route
} from 'react-router-dom';
import BasicProfileSec from './components/BasicProfileSec';
import AppearProfileSec from './components/AppearProfileSec';
import SubsProfileSec from './components/SubsProfileSec';
import Login from "./components/Login"
import Signup from "./components/Signup"
import MediaDoc from './components/MediaDoc';
import Profile_two from './components/Profile_two.js';
import ContactPage from './components/ContactPage.js';

function App() {
  return (
    <>
      <BrowserRouter>

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/studio" element={<StudioPage />} />
          <Route path="/studio-detail/:id" element={<StudioDetail />} />
          <Route path="/download-section" element={<DownloadSection />} />
          <Route path="/editProfile" element={<ProfileEdit />} />
          <Route path="/info" element={<ProfileCreated />} />
          <Route path="/basic-profile-sec" element={<BasicProfileSec />} />
          <Route path="/apperance" element={<AppearProfileSec />} />
          <Route path="/subscription" element={<SubsProfileSec />} />
          <Route path="/media/photos" element={<ProfileCreatedMedia />} />
          <Route path="/login" element={<Login/>} />
          <Route path="/signup" element={<Signup/>}/>
          <Route path="/media/videos" element={<MediaVideo/>}/>
          <Route path="/media/audios" element={<MediaAudio/>}  />
          {/* <Route path="/media-doc" element={<MediaDoc/>} /> */}
          <Route path="/profile" element={<Profile/>}/>
          <Route path="/editapperance" element={<Profile_two/>}/>

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
