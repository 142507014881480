import React from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import audioIcon from "./image/mic.svg";
import add from "./image/add.svg";
import emptyIcon from "./image/emptyIcon.svg";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ref, uploadBytes,uploadBytesResumable, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../Firebase"; // assuming that your Firebase module is in a file called firebase.js
import { v4 } from "uuid";
import "./css/ProfileCreatedMedia.css";
import profileImage from "./image/profile-face.svg";
import mPin from "./image/mpin.svg";
import rightArrow from "./image/right_arrow.svg";
import Nav from "./Nav";
import url from "../constData";
import { useContext } from "react";
import { AppContext } from "../Contextapi";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import placeholderProfile from "./image/ProfilePlaceholder.png";

function MediaAudio() {
  const navigate = useNavigate();
  const { data: userData } = useContext(AppContext);
  const [audio, setAudio] = useState(null);
  const [open, setOpen] = useState(false);
  
  const toPhotos = () => {
    navigate("/media/photos");
  };
  const toVideos = () => {
    navigate("/media/videos");
  };
  const toDoc = () => {
    navigate("/media-doc");
  };



  const handleUpload = async (e) => {
    setAudio(e.target.files[0]);
  };

  useEffect(() => {
    if (audio) {
      (async function () {
        setOpen(true);
        const audioRef = ref(storage, `audios/${audio.name + v4()}`);
        await uploadBytesResumable(audioRef, audio);
        const audioUrl = await getDownloadURL(audioRef);
    
        await axios.post(
          `${url}/api/upload/media`,
          {
            mediaType: "audios",
            media: audioUrl,
          },
          {
            headers: {
              type: "application/json",
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );
        setOpen(false);
      })();
    }
  }, [audio]);



  // const [audioUpload, setAudioUpload] = useState(null);
  // const [audioUrls, setAudioUrls] = useState([]);

  // const audiosListRef = ref(storage, "audios/");
  // const uploadAudio = () => {
  //   if (audioUpload == null) return;
  //   const audioRef = ref(storage, `audios/${audioUpload.name + v4()}`);
  //   uploadBytes(audioRef, audioUpload).then((snapshot) => {
  //     getDownloadURL(snapshot.ref).then((url) => {
  //       setAudioUrls((prev) => [...prev, url]);
  //     });
  //   });
  // };

  // useEffect(() => {
  //   listAll(audiosListRef).then((response) => {
  //     response.items.forEach((item) => {
  //       getDownloadURL(item).then((url) => {
  //         setAudioUrls((prev) => [...prev, url]);
  //       });
  //     });
  //   });
  // }, []);

  return (
    <>
    <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Nav />
      <div className="body-sec">
        <div className="profile-created-section">
          <div className="left-section">
            <button onClick={toPhotos} className="photo-btn left-btn ">
              <span>Photos</span>
              <img src={rightArrow} alt="Right-Arrow" />
            </button>
            <button onClick={toVideos} className="video-btn left-btn ">
              <span>Videos</span>
              <img src={rightArrow} alt="Right-Arrow" />
            </button>
            <button
              className="audio-btn left-btn "
              style={{ backgroundColor: "#F9D422" }}
            >
              <span>Audios</span>
              <img src={rightArrow} alt="Right-Arrow" />
            </button>
            {/* <button onClick={toDoc} className="doc-btn left-btn ">
              <span>Documents</span>
              <img src={rightArrow} alt="Right-Arrow" />
            </button> */}
          </div>

          <div className="right-section" style={{ overflow: "auto" }}>
            <div className="right-top">
              <Link
                to="/info"
                style={{ textDecoration: "none", color: "black" }}
              >
                <span>Detail</span>
              </Link>
              <Link to="/media/photos">
                <span>Media</span>
              </Link>
            </div>
            <div className="right-profile-section">
              <div className="image-sec">
                <img
                  src={userData?.profilePic!==""?userData?.profilePic:placeholderProfile}
                  style={{ height: "22vh", width: "10vw" }}
                  alt="profile-face"
                />
                {/* <div className="img-change-sec">
                                <span className='fg-blue-btn'>Change</span>
                                <span className='fg-blue-btn'>Delete</span>
                            </div> */}
              </div>
              <div className="p-detail-sec">
                <span className="font-35 mr-y-10">{userData?.fname}</span>
                {/* <span className="font-25 ">Your Profile Strength</span> */}
                <span className="mr-y-10">
                  <img src={mPin} alt="mpin-icon" /> {userData?.location}
                </span>
              </div>
              <div className="editBtn-sec">
                <Link to="/editProfile">
                  <span>Edit</span>
                </Link>
              </div>
            </div>

            <div className="r-b-top">
              <label>
                <img
                  src={audioIcon}
                  style={{ cursor: "pointer" }}
                  alt="audio-icon"
                />
                {/* <input
                  type="file"
                  style={{ display: "none" }}
                  accept="audio/*"
                  onChange={(event) => {
                    setAudioUpload(event.target.files[0]);
                  }}
                /> */}
              </label>

              <div className="picture-section">
                <span>{userData?.audios.length}</span>
                {/* <span onClick={uploadAudio}>
                  <img src={add} style={{ cursor: "pointer" }} alt="add-icon" />
                </span> */}
                <label htmlFor="audio-input">
                  <img src={add} style={{ cursor: "pointer" }} alt="add-icon" />
                  <input
                  type="file"
                  style={{ display: "none" }}
                  accept="audio/*"
                  onChange={handleUpload}
                  id="audio-input"
                /> 
                </label>
              </div> 
            </div>
            <div className={userData?.audios.length>0?"r-b-data2":"r-b-data empty-area"}>
              {/* {audioUrls.map((url) => {
                return (
                  <audio controls>
                    <source src={url} type="audio/mpeg" />
                  </audio>
                );
              })} */}
              
              {userData?.audios.length>0? userData?.audios.map((url) => {
                return (
                  <div style={{margin:"0 10px"}}>
                  <audio controls>
                    <source src={url} type="audio/mpeg" />
                  </audio>
                  </div>
                );
              }):<><img src={emptyIcon} alt="empty-icon" />
              <span className="grey-text">
                    You don't have any audio added yet
                </span></>}
              
              
            </div>
          </div>
        </div>
      </div>
      </>
  );
}

export default MediaAudio;
