import React from "react";

import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../Firebase"; // assuming that your Firebase module is in a file called firebase.js
import { v4 } from "uuid";

import "./css/ProfileCreatedMedia.css";
import profileImage from "./image/profile-face.svg";
import mPin from "./image/mpin.svg";
import rightArrow from "./image/right_arrow.svg";

import camera from "./image/camera.svg";
import add from "./image/add.svg";
import m1 from "./image/m1.png";
import m2 from "./image/m2.png";
import m3 from "./image/m3.png";

import BasicProfileSec from "./BasicProfileSec";
import AppearProfileSec from "./AppearProfileSec";
import SubsProfileSec from "./SubsProfileSec";
import MediaPhoto from "./MediaPhoto";
import MediaVideo from "./MediaVideo";
import MediaAudio from "./MediaAudio";
import MediaDoc from "./MediaDoc";
import Nav from "./Nav";
import url from "../constData";
import { useContext } from "react";
import { AppContext } from "../Contextapi";
import placeholderProfile from "./image/ProfilePlaceholder.png";


function ProfileCreatedMedia() {
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [preview, setPreview] = useState(null);
  const token = localStorage.getItem("token");
  const [data, setdata] = useState([]);
  const id = localStorage.getItem("id");
  const { data: userData } = useContext(AppContext);


  
  // const imagesListRef = ref(storage, "images/");
  // const uploadFile = () => {
  //   if (imageUpload == null) return;
  //   const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
  //   uploadBytes(imageRef, imageUpload).then((snapshot) => {
  //     getDownloadURL(snapshot.ref).then((url) => {
  //       setImageUrls((prev) => [...prev, url]); 
  //       console.log(url);
  //     });
  //   });
  // };

  // useEffect(() => {
  //   listAll(imagesListRef).then((response) => {
  //     response.items.forEach((item) => {
  //       getDownloadURL(item).then((url) => {
  //         setImageUrls((prev) => [...prev, url]);
  //       });
  //     });
  //   });
  // }, [imageUpload]);

  const navigate = useNavigate();
  const toVideos = () => {
    navigate("/media/videos");
  };
  const toAudios = () => {
    navigate("/media/audios");
  };
  const toDoc = () => {
    navigate("/media-doc");
  };

  return (
    <>
      <Nav/>

      <div className="body-sec">
        <div className="profile-created-section">
          <div className="left-section">
            <button className="photo-btn left-btn " style={{backgroundColor:'#F9D422'}}>
              <span>Photos</span>
              <img src={rightArrow} alt="Right-Arrow" />
            </button>
            <button onClick={toVideos} className="video-btn left-btn ">
              <span>Videos</span>
              <img src={rightArrow} alt="Right-Arrow" />
            </button>
            <button onClick={toAudios} className="audio-btn left-btn ">
              <span>Audios</span>
              <img src={rightArrow} alt="Right-Arrow" />
            </button>
            {/* <button onClick={toDoc} className="doc-btn left-btn ">
              <span>Documents</span>
              <img src={rightArrow} alt="Right-Arrow" />
            </button> */}
          </div>
          <div className="right-section" style={{ overflow: "auto",}}>
            <div className="right-top">
              <Link to="/info" style={{textDecoration:'none', color:'black'}}>
                <span>Detail</span>
              </Link>
              <Link to="/media/photos">
                <span>Media</span>
              </Link>
            </div>

            <div className="right-profile-section">
              <div className="image-sec">
                <img
                  src={userData?.profilePic!==""?userData?.profilePic:placeholderProfile}
                  style={{ height: "22vh", width: "10vw" }}
                  alt="profile-face"
                />
                {/* <div className="img-change-sec">
                                <span className='fg-blue-btn'>Change</span>
                                <span className='fg-blue-btn'>Delete</span>
                            </div> */}
              </div>
              <div className="p-detail-sec">
                <span className="font-35 mr-y-10">{userData?.fname}</span>
                {/* <span className="font-25 ">Your Profile Strength</span> */}
                <span className="mr-y-10">
                  <img src={mPin} alt="mpin-icon" /> {userData?.location}
                </span>
              </div>
              <div className="editBtn-sec">
                <Link to="/editProfile">
                  <span>Edit</span>
                </Link>
              </div>
            </div>
            <MediaPhoto />

            {/* <div className="right-bottom">
              <div className="r-b-top">
                <label htmlFor="image-input">
                  <img
                    src={camera}
                    style={{ cursor: "pointer" }}
                    alt="camera-icon"
                  />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    id="image-input"
                    onChange={(event) => {
                      setImageUpload(event.target.files[0]);
                    }}
                  />
                </label>
                <div className="picture-section">
                  <span>{imageUrls.length} photos</span>
                  <span onClick={uploadFile} style={{ cursor: "pointer" }}>
                    {" "}
                    <img src={add} alt="add-icon" />{" "}
                  </span>
                </div>
              </div>
              <div className="r-b-data" style={{ marginBottom: "5vh" }}>
                {imageUrls.map((url) => {
                  return <img src={url} />;
                })}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileCreatedMedia;
