import React,{useState,useEffect,createContext} from 'react';
import axios from 'axios';
import url from './constData';
import useSWR from 'swr'


export const AppContext = createContext({data:null});
export const AppProvider = ({children}) => {
    const token = localStorage.getItem("token");
    const fetcher = (url) => axios.get(url,{
      headers: {
        type: "application/json",
        "x-auth-token": token,
      },
    }).then((res)=>{
      return res.data;
    });
    const { data, error, loading } = useSWR(
      `${url}/api/audition/getUserData`,
      fetcher,
    {
      refreshInterval: 3000,
    }
    );
    const fetcher1 = (url) => axios.get(url).then((res)=>{return res.data});
    const { data:studioData, error: studioError, loading: studioLoading } = useSWR(
      `${url}/api/audition/getAllStudio`,
      fetcher1,
    {
      refreshInterval: 3000,
    }
    );
 
    return (
        <AppContext.Provider value={{data,studioData}}>
            {children}
        </AppContext.Provider>
    )
}


