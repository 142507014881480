import React from 'react';
import Nav from './Nav';
import './css/ContactPage.css';
import contactImg from './image/contact.svg';
import axios from 'axios';
import url from '../constData';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const ContactPage = () => {
    const [FormData, setFormData] = React.useState({
        email: '',
        number: '',
        message: ''
    });
    const [msgSuceess, setMsgSuccess] = React.useState(false);
    const [msgError, setMsgError] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const { email, number, message } = FormData;
    const onChange = e => setFormData({ ...FormData, [e.target.name]: e.target.value });
    const onSubmit = async e => {
        e.preventDefault();
        try {
            setOpen(true);
            const res = await axios.post(`${url}/api/contact`, {email,number,message},{
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (res.status === 200) {
                setOpen(false);
                setFormData({
                    email: '',
                    number: '',
                    message: ''
                });
                setMsgSuccess(true);
                setTimeout(() => {
                    setMsgSuccess(false);
                }, 3000);
            }
            

        } catch (error) {
            setOpen(false);
            console.log(error);
            setMsgError(true);
            setTimeout(() => {
                setMsgError(false);
            }, 3000);
        }
    };
  return (
    <>
    <Nav/>
    <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    <div className="content">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h3 className="heading mb-4">Let's talk about everything!</h3>
                        <p>Whether you have questions, ideas, or just want to say hello, feel free to reach out. We're here to help!</p>
                        <p style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',marginRight:'2vmax'}}><img src={contactImg} alt="Image" style={{ maxWidth: '70%' }} /></p>
                    </div>
                    <div className="col-md-6">
                        <form style={{width:'30vmax'}} onSubmit={onSubmit}>
                            <div className="form-group">
                                <input type="text" className="form-control" name="email" id="email" placeholder="Email" required onChange={e => onChange(e)} value={email} />
                            </div>
                            <div className="form-group">
                                <input type="number" className="form-control" name="number" id="number" placeholder="Enter your Number" required onChange={e => onChange(e)} value={number} />
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message" required onChange={e => onChange(e)}
                                    value={message}>
                                 </textarea>
                            </div>
                            <div className="form-group">
                                <input type="submit" value="Send Message" className="btn-primary rounded-0 py-2 px-4" />
                                <span className="submitting"></span>
                            </div>
                        </form>
                        
                        { msgError &&
                            <div id="form-message-warning" className="mt-4">
                            Oops, something went wrong. Please try again later.
                        </div>}
                        { msgSuceess &&
                            <div id="form-message-success">
                            Your message was sent, thank you!
                        </div>}
                    </div>
                </div>
            </div>
        </div>
   
    </>
  )
}

export default ContactPage