import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword ,updateProfile } from "firebase/auth";
import { collection, doc, getDocs, where, query, setDoc } from 'firebase/firestore';
import { auth, db } from "../Firebase";
import "./css/Login.css";
import grayCurve from "../components/image/grayCurve.png";
import cuate from "../components/image/cuate.png";
import man from "../components/image/man.png";
import lock from "../components/image/lock.png";
import phone from "../components/image/phone.png";
import mail from "../components/image/mail.png";
import { Link } from "react-router-dom";
import axios from "axios";
import url from "../constData";
import OtpInput from "react-otp-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Backdrop, CircularProgress } from "@mui/material";


const Signup = () => {
  const navigate = useNavigate();
  const [fname, setfname] = useState();
  const [number, setnumber] = useState();
  const [email, setemail] = useState();
  const [password, setpassword] = useState();
  const [otp, setOtp] = useState();
  const [otpVerifyModal, setOtpVerifyModal] = useState(false); 
  const [otpVerified, setOtpVerified] = useState(false);
  const [open, setOpen] = useState(false);

  const handlefname = (e) => {
    setfname(e.target.value.toLowerCase().trim());
  };
  const handleemail = (e) => {
    setemail(e.target.value.toLowerCase().trim());
  };
  const handlenumber = (e) => {
    setnumber(e.target.value.trim());
  };

  const handlepassword = (e) => {
    setpassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpen(true);
    if(!fname || !email || !number || !password){
      toast.error("Please fill all the fields", {
        position: toast.POSITION.TOP_RIGHT,
        className: 'toast-message',
      });
      setOpen(false);
      return;
    }
    if(password.length < 8){
      toast.error("Password must be atleast 8 characters long", {
        position: toast.POSITION.TOP_RIGHT,
        className: 'toast-message',
      });
      setOpen(false);
      return;
    }
    try {
      setOpen(true);
      const res1 = await axios.post(
        `${url}/api/audition/web/signup`,
        { fname, email, number, password },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if(res1.status === 200){  
        setOpen(false);
        setOtpVerifyModal(true);
      }
      
    } catch (err) {
      console.log(err);
      toast.error(`${err?.response?.data.msg || err.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-message",
      });
      setOpen(false);
    }
  };

  const verifyOtp = async (event) => {
    event.preventDefault();
    setOpen(true);
    try {
      const response = await axios.post(
        `${url}/api/audition/web/verify-otp`,
        {
          fname: fname,
          number: number,
          email: email,
          password: password,
          otp: otp,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      if(response.status === 200){
        if(response.data.created==="create"){
          createUserWithEmailAndPassword(
            auth,
            email,
            `${email}password`
          ).then(async( res) => {
            await setDoc(doc(db, "users", `${response.data._id}`), {
              "fullName": fname,
              "email": email,
              "groups": [],
              "notification": [],
              "profilePic": "https://firebasestorage.googleapis.com/v0/b/yourfriday-4f51b.appspot.com/o/ProfilePlaceholder.png?alt=media&token=c1568ccd-5dc7-4256-be76-85f07f6dabee",
              "uid": response.data._id,
          });
          sessionStorage.setItem("created", "yes");
                      setOpen(false);
                      setOtpVerifyModal(true);
          });
        }
        else if(response.data.created==="exist"){
          signInWithEmailAndPassword(auth, email, `${email}password`).then(async (res) => {
            console.log(res.user.uid);
            await setDoc(doc(db, "users", `${response.data._id}`), {
                "fullName": fname,
                "email": email,
                "groups": [],
                "notification": [],
                "profilePic": "https://firebasestorage.googleapis.com/v0/b/yourfriday-4f51b.appspot.com/o/ProfilePlaceholder.png?alt=media&token=c1568ccd-5dc7-4256-be76-85f07f6dabee",
                "uid": response.data._id,
            });
            sessionStorage.setItem("created", "yes");
            setOpen(false);
            setOtpVerifyModal(true);
        })
        }else{
          toast.error("Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toast-message',
        });
        }
        setOtpVerifyModal(false);
        setOtpVerified(true);
        setOpen(false);
      }
    } catch (error) {
      setOpen(false);
      if (error.response.status === 400) {
        toast.error(`${error?.response?.data.msg || error.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "toast-message",
        });
      } else {
        toast.error(`${error?.response?.data.msg || error.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "toast-message",
        });
      }
      console.log(`error status -> ${error.response.status}`);
      console.log(`error is here -> ${error.response.data.msg}`);
    }
  };

  if (otpVerified) {
    navigate("/login");
  }

  return (
    <>
      <ToastContainer />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      
      <Modal open={otpVerifyModal} >
        <div className="otpVerifyModal">
          <h1 className="login_text">Verify OTP</h1>
          <div className="username_div">
            <form className="otpForm" id="form2" onSubmit={verifyOtp}>
              <label>Enter verification code</label>

              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={
                  <span className="otpInputSeprater">-</span>
                }
                renderInput={(props) => (
                  <input
                    {...props}
                    className="otpInput"
                    placeholder="0"
                  />
                )}
                className="otpInput"
              />

              {/* <input
                  className={styles.signupInput}
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                /> */}
              <button
                type="submit"
                form="form2"
                className="verify_button"
              >
                Verify
              </button>
            </form>
          </div>
        </div>
      </Modal>
      <div style={{ display: "flex" }}>
        <div className="login-left">
          <img className="grayCurve" src={grayCurve} />
          <img className="cuate-login" src={cuate} />
        </div>
        <div className="login-right">
          <div className="login-form">
            <h1 style={{ textAlign: "center" }}> Welcome </h1>
            <h3 style={{ marginTop: "4vh" }}> Fullname </h3>
            <input
              type="text"
              value={fname}
              onChange={handlefname}
              placeholder="Enter your name"
              style={{
                background: "#FDF5F2",
                height: "5vh",
                width: "26vw",
                border: "none",
                marginTop: "2vh",
                paddingLeft: "3vw",
                position: "relative",
              }}
            />
            <img
              src={man}
              style={{
                position: "absolute",
                height: "3.6vh",
                marginLeft: "-28.5vw",
                marginTop: "2.5vh",
              }}
            />
            <h3 style={{ marginTop: "4vh" }}>Phone No. </h3>
            <input
              type="number"
              value={number}
              onChange={handlenumber}
              placeholder="Enter your phone number"
              style={{
                background: "#FDF5F2",
                height: "5vh",
                width: "26vw",
                paddingLeft: "3vw",
                border: "none",
                marginTop: "2vh",
              }}
            />
            <img
              src={phone}
              style={{
                position: "absolute",
                height: "3.6vh",
                marginLeft: "-28.5vw",
                marginTop: "2.5vh",
              }}
            />
            <h3 style={{ marginTop: "4vh" }}>Email Id</h3>
            <input
              type="text"
              value={email}
              onChange={handleemail}
              placeholder="Enter your Email Id"
              style={{
                background: "#FDF5F2",
                height: "5vh",
                width: "26vw",
                paddingLeft: "3vw",
                border: "none",
                marginTop: "2vh",
              }}
            />
            <img
              src={mail}
              style={{
                position: "absolute",
                height: "3.6vh",
                marginLeft: "-28.5vw",
                marginTop: "2.5vh",
              }}
            />

            <h3 style={{ marginTop: "4vh" }}>Password</h3>
            <input
              type="password"
              value={password}
              onChange={handlepassword}
              placeholder="Enter password"
              style={{
                background: "#FDF5F2",
                height: "5vh",
                width: "26vw",
                paddingLeft: "3vw",
                border: "none",
                marginTop: "2vh",
              }}
            />
            <img
              src={lock}
              style={{
                position: "absolute",
                height: "3.6vh",
                marginLeft: "-28.5vw",
                marginTop: "2.5vh",
              }}
            />

            <button onClick={handleSubmit} className="login-btn">
              Sign Up
            </button>

            <h3 style={{ marginTop: "4vh", textAlign: "center" }}>
              {" "}
              Already have an account? <Link to="/login"> Log In </Link>{" "}
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};
export default Signup;

