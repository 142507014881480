import { useState } from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth'
import './css/Login.css'
import { auth } from '../Firebase'
import grayCurve from "../components/image/grayCurve.png"
import cuate from "../components/image/cuate.png"
import man from "../components/image/man.png"
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import url from '../constData';
import lock from "../components/image/lock.png"
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login =()=>{
    const navigate = useNavigate()
    const [email,setemail] = useState("")
    const [password,setpassword] = useState("")
    const [open, setOpen] = useState(false);
    const handleemail=(e)=>[
        setemail(e.target.value)
    ]
    const handlepassword=(e)=>{
        setpassword(e.target.value)
    }

    const handleSubmit = async (e) => {
        setOpen(true);
        if(!email || !password){
            toast.error("Please fill all the fields", {
                position: toast.POSITION.TOP_RIGHT,
                className: 'toast-message',
              });
              setOpen(false);
              return;
        }

        if(password.length < 8){
            toast.error("Password must be atleast 8 characters long", {
                position: toast.POSITION.TOP_RIGHT,
                className: 'toast-message',
              });
              setOpen(false);
              return;
        }

        e.preventDefault();
        try {
            setOpen(true);
       const ress = await signInWithEmailAndPassword( auth , email.trim() , `${email.trim()}password`)
      const res = await axios.post(`${url}/api/audition/login` , { email , password },
     {
        headers: { 'Content-Type': 'application/json' ,
 }
      })
          navigate("/info");
          if(res.data){
            localStorage.setItem("token",res.data.token)
            localStorage.setItem("id",res.data._id)
            setOpen(false);
            navigate("/")
            window.location.reload(false);
        }
        } catch (error) {
          const errorMessage = error.message;
        //   console.log(errorMessage);
        toast.error(`${error?.response?.data.msg || error.message}`, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toast-message',
          });
          setOpen(false);
        }
      };
    return(
        <>
        <ToastContainer />
        <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <div style={{display:"flex"}}>
           <div className='login-left'>
           <img className='grayCurve' src={grayCurve} /> 
           <img className='cuate-login' src={cuate} />
        </div>
            <div className='login-right'>
            <div className="login-form">
            <h1 style={{textAlign:"center"}}>Welcome Back!!</h1>
            <h3 style={{marginTop:"4vh"}}>Email</h3>
            <img src={man} style={{position:"absolute" ,height:'1.5vw', marginTop:"2.8vw",marginLeft:'0.5vw',zIndex:50}}/>
            <input type="text" value={email} onChange={handleemail} placeholder="Enter your Email" style={{background: "#FDF5F2" ,height:"3vw" , width:"26vw" , paddingLeft:"3vw", border:"none" , marginTop:"2vw" ,position:"relative",borderRadius:'0.3vw'}} />
            <h3 style={{marginTop:"4vh"}}>Password</h3>
            <img src={lock} style={{position:"absolute"  ,height:'1.5vw', marginTop:"2.8vw",marginLeft:'0.5vw',zIndex:50} } />
            <input type="password" value={password} onChange={handlepassword} placeholder="Enter password" style={{background: "#FDF5F2" , height:"3vw" , width:"26vw" , paddingLeft:"3vw", border:"none" , marginTop:"2vw",position:'relative',borderRadius:'0.3vw'}}  />
            

            <button onClick={handleSubmit} className='login-btn'>Log in</button>
                   
            <h3 style={{ marginTop:"4vh" , textAlign:"center"}}> Dont have an account yet? <Link to="/signup" style={{color:'black'}}> Sign up </Link> </h3>
            </div>
           
           </div>
        </div>
        </>
    )
}

export default Login
