// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBxcjrAeDqVKhgMbmjacaf15ntxsA4cMm0",
  authDomain: "yourfriday-4f51b.firebaseapp.com",
  projectId: "yourfriday-4f51b",
  storageBucket: "yourfriday-4f51b.appspot.com",
  messagingSenderId: "727482973574",
  appId: "1:727482973574:web:81a6055224f52a7ec34007",
  measurementId: "G-EZ4YHJSJ90"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore();
export const auth = getAuth();
export const storage = getStorage(app);






